<template>
  <div class="form-survey">
    <Loading :isLoading="isLoading"></Loading>
    <div class="lang">
      <span
        class="show-lang"
        style="margin-top: 10px;
    margin-right: 7px;"
        @click="switchLang('EN')"
        >EN</span
      ><span
        class="show-lang"
        style="margin-top: 10px;"
        @click="switchLang('TH')"
        >TH /</span
      >
    </div>
    <div class="app-container">
      <form @submit="submitForm">
        <div class="row">
          <div class="col-12" style="text-align:center">
            <h3>
              {{ showTitleForm }}
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                style="width:100px height: 38px; margin-bottom: 10px; margin-left:14px; height: 40px;"
                @click="logout"
              >
                <p
                  class="description"
                  style="margin-top: 3px; margin-right: 8px;"
                >
                  <i
                    class="bi bi-box-arrow-in-left"
                    style="margin-left:10px margin-right: 4px;"
                  ></i
                  >Log out
                </p>
              </button>
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 offset-md-1 offset-lg-4">
            <div class="timeline">
              <div class="form-group row">
                <label class="col-sm-8 col-form-label label-timeline">{{
                  showFactory
                }}</label>
                <div class="col-sm-4 plantselect">
                  <vue-select
                    required
                    v-model="formData.Plant"
                    :clear-on-select="true"
                    label-by="name"
                    searchable
                    :close-on-select="true"
                    :min="1"
                    :options="options"
                  ></vue-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 offset-md-1  offset-lg-4">
            <div class="timeline">
              <div class="form-group row" style="text-align:center">
                <label class="col-sm-2 col-form-label label-timeline"
                  >Timeline</label
                >
                <div class="col-sm-4" style="margin-bottom:10px;">
                  <Datepicker
                    v-model="formData.dateFrom"
                    :upper-limit="formData.dateTo"
                  ></Datepicker>
                </div>
                <label class="col-sm-1 col-md-2 label-timeline">To</label>
                <div class="col-sm-4">
                  <Datepicker
                    v-model="formData.dateTo"
                    :lower-limit="formData.dateFrom"
                    :upper-limit="new Date()"
                  ></Datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="form-group row"
          v-for="(item, index) in renderDataByMode"
          :key="index"
        >
          <label class="col-sm-3 col-lg-2 col-form-label subject"
            >{{ index + 1 }}. {{ item.subject }}</label
          >
          <div class="col-sm-9 col-lg-10" style="margin-top: 10px;">
            <div v-for="(c, indexC) in item.choices" :key="indexC">
              <label>
                <input
                  type="radio"
                  :name="item.type"
                  :value="c.value"
                  required
                  v-model="answers[index]"
                />
                {{ c.text }} </label
              ><br />
            </div>
          </div>
        </div>
        <div class="operation">
          <button
            class="btn btn-success"
            type="submit"
            style="width:200px; margin-bottom: 10px; margin-right:10px; margin-left:24px;"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <ModalResult
      v-if="showModal"
      :showModal="showModal"
      :formData="formData"
      :riskLevel="riskLevel"
      @closeModal="showModal = false"
    ></ModalResult>
  </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import Moment from "moment";
import { defineAsyncComponent } from "vue";
const Loading = defineAsyncComponent(() =>
  import("@/components/loading-overlay")
);
const ModalResult = defineAsyncComponent(() => import("./components/modal"));
import VueSelect from "vue-next-select";
import PlantData from "@/masterData/plant.json";
import _ from "lodash";
import SwAlert from "@/js/sweetAlert.js";
import Axios from "axios";
import { baseSubmitUrl } from "@/config/api.js";
const riskLevel = {
  veryHigh: "เสี่ยงสูงมาก",
  high: "เสี่ยงสูง",
  normal: "เสี่ยงปานกลาง",
  zero: "เสี่ยงต่ำ",
};
export default {
  components: {
    Datepicker,
    Loading,
    ModalResult,
    VueSelect,
  },
  data() {
    return {
      answers: [],
      isLoading: false,
      formData: {
        dateFrom: Moment("29/04/2021", "DD/MM/YYYY")._d,
        dateTo: new Date(),
      },
      titleForm: {
        th: "แบบสอบถามเพื่อคัดกรองก่อนเข้าพื้นที่ หลังเทศกาลวันแรงงานและวันฉัตรมงคล 2564",
        en: "Risk Evaluation Survey before entering SCGP premises (After Labor and Coronation Day 2021)",
      },
      factoryForm: {
        th: "โรงงาน/สำนักงานที่ต้องการเข้าไปปฏิบัติงาน",
        en: "Factory/Deparment",
      },
      riskLevel: riskLevel,
      showModal: false,
      result: {},
      mode: localStorage.getItem("lang") || "TH",
      options: PlantData,
      dataRender: {
        th: [
          {
            subject: "การเดินทาง",
            type: "journey",
            choices: [
              {
                text: "เดินทางกลับภูมิลำเนา",
                value: { name: "เดินทางกลับภูมิลำเนา", value: 2 },
              },
              {
                text: "กลับภูมิลำเนาอยู่กับบ้านไม่ได้ไปไหน",
                value: {
                  name: "กลับภูมิลำเนาอยู่กับบ้านไม่ได้ไปไหน",
                  value: 1,
                },
              },
              {
                text: "ไม่ได้เดินทาง",
                value: { name: "ไม่ได้เดินทาง", value: 0 },
              },
            ],
          },
          {
            subject: "ยานพาหนะที่ใช้",
            type: "vehicle",
            choices: [
              {
                text:
                  "เครื่องบิน/รถทัวร์/รถเมล์/รถไฟ/รถไฟฟ้า/รถตู้สาธารณะ/เรือ",
                value: {
                  name:
                    "เครื่องบิน/รถทัวร์/รถเมล์/รถไฟ/รถไฟฟ้า/รถตู้สาธารณะ/เรือ",
                  value: 2,
                },
              },
              {
                text: "แท็กซี่",
                value: { name: "แท็กซี่", value: 1 },
              },
              {
                text: "รถส่วนตัว",
                value: { name: "รถส่วนตัว", value: 0 },
              },
            ],
          },
          {
            subject: "จังหวัดที่ได้ทำกิจกรรมต่างๆ ",
            type: "province",
            choices: [
              {
                text: "กรุงเทพมหานคร / นนทบุรี / ชลบุรี / เชียงใหม่ / ปทุมธานี / สมุทรปราการ",
                value: {
                  name: "กรุงเทพมหานคร / นนทบุรี / ชลบุรี / เชียงใหม่ / ปทุมธานี / สมุทรปราการ",
                  value: 2,
                },
              },
              {
                text: "จังหวัดอื่นๆ",
                value: { name: "จังหวัดอื่นๆ", value: 1 },
              },
            ],
          },
          {
            subject: "สถานที่/กิจกรรม",
            type: "activity",
            choices: [
              {
                text: "ได้ไปผับ บาร์ สถานบันเทิง ",
                value: {
                  name: "ได้ไปผับ บาร์ สถานบันเทิง ",
                  value: riskLevel.veryHigh,
                },
              },
              {
                text: "มีการรวมกลุ่มกิจกรรม เช่น งานเลี้ยง พบปะพูดคุย",
                value: {
                  name: "มีการรวมกลุ่มกิจกรรม เช่น งานเลี้ยง พบปะพูดคุย",
                  value: 2,
                },
              },
              {
                text: "ได้ออกนอกบ้าน ไปทำธุระเป็นบางครั้งคราว",
                value: {
                  name: "ได้ออกนอกบ้าน ไปทำธุระเป็นบางครั้งคราว",
                  value: 1,
                },
              },
              {
                text: "อยู่ในบ้านและทานข้าวในครอบครัว",
                value: { name: "อยู่ในบ้านและทานข้าวในครอบครัว", value: 0 },
              },
            ],
          },
          {
            subject: "บุคคลที่สัมผัส/ใกล้ชิด",
            type: "personal",
            choices: [
              {
                text: "ใกล้ชิดสัมผัสผู้ติดเชื้อ",
                value: {
                  name: "ใกล้ชิดสัมผัสผู้ติดเชื้อ",
                  value: riskLevel.veryHigh,
                },
              },
              {
                text:
                  "ใกล้ชิดผู้สัมผัสเสี่ยงสูง(ถูกผู้ป่วยไอจามใส่/อยู่บ้านเดียวกับผู้ติดเชื้อ/พูดคุยกับผู้ป่วยในระยะ 1 เมตรเกิน 5นาที/อยู่ในสถานที่แออัดร่วมกับผู้ป่วยในระยะ 1 เมตรเกิน 15 นาที)",
                value: {
                  name:
                    "ใกล้ชิดผู้สัมผัสเสี่ยงสูง(ถูกผู้ป่วยไอจามใส่/อยู่บ้านเดียวกับผู้ติดเชื้อ/พูดคุยกับผู้ป่วยในระยะ 1 เมตรเกิน 5นาที/อยู่ในสถานที่แออัดร่วมกับผู้ป่วยในระยะ 1 เมตรเกิน 15 นาที)",
                  value: 2,
                },
              },
              {
                text:
                  "ใกล้ชิดผู้สัมผัสเสี่ยงต่ำ/คนในครอบครัวทำงานกับกลุ่มเสี่ยง",
                value: {
                  name:
                    "ใกล้ชิดผู้สัมผัสเสี่ยงต่ำ/คนในครอบครัวทำงานกับกลุ่มเสี่ยง",
                  value: 1,
                },
              },
              {
                text: "ไม่ได้สัมผัส",
                value: { name: "ไม่ได้สัมผัส", value: 0 },
              },
            ],
          },
        ],
        en: [
          {
            subject: "Traveling form",
            type: "journey",
            choices: [
              {
                text: "Traveled back to hometown and visited other places",
                value: { name: "เดินทางกลับภูมิลำเนา", value: 2 },
              },
              {
                text: "Traveled back to hometown and stayed at home",
                value: {
                  name: "กลับภูมิลำเนาอยู่กับบ้านไม่ได้ไปไหน",
                  value: 1,
                },
              },
              {
                text: "Did not travel",
                value: { name: "ไม่ได้เดินทาง", value: 0 },
              },
            ],
          },
          {
            subject: "Transportation mode",
            type: "vehicle",
            choices: [
              {
                text:
                  "Airplane / Coach / Bus / Train / Skytrain / Subway / Public van / Boat",
                value: {
                  name:
                    "เครื่องบิน/รถทัวร์/รถเมล์/รถไฟ/รถไฟฟ้า/รถตู้สาธารณะ/เรือ",
                  value: 2,
                },
              },
              {
                text: "Taxi",
                value: { name: "แท็กซี่", value: 1 },
              },
              {
                text: "Personal vehicle",
                value: { name: "รถส่วนตัว", value: 0 },
              },
            ],
          },
          {
            subject: "Province you stayed at or visited",
            type: "province",
            choices: [
              {
                text:
                  "Bangkok / Nonthaburi / Chon Buri / Chiang Mai / Pathum Thani / Samut Prakan",
                value: {
                  name: "กรุงเทพมหานคร / นนทบุรี / ชลบุรี / เชียงใหม่ / ปทุมธานี / สมุทรปราการ",
                  value: 2,
                },
              },
              {
                text: "Other provinces",
                value: { name: "จังหวัดอื่นๆ", value: 1 },
              },
            ],
          },
          {
            subject: "Places / Activities",
            type: "activity",
            choices: [
              {
                text: "Pub / Bar / Entertainment places",
                value: {
                  name: "ได้ไปผับ บาร์ สถานบันเทิง",
                  value: riskLevel.veryHigh,
                },
              },
              {
                text:
                  "Been in a gathering e.g. dinner, party, meetings",
                value: {
                  name: "มีการรวมกลุ่มกิจกรรม เช่น งานเลี้ยง พบปะพูดคุย",
                  value: 2,
                },
              },
              {
                text: "Occasionally did some errands",
                value: {
                  name: "ได้ออกนอกบ้าน ไปทำธุระเป็นบางครั้งคราว",
                  value: 1,
                },
              },
              {
                text: "Stayed home and have meals with family",
                value: { name: "อยู่ในบ้านและทานข้าวในครอบครัว", value: 0 },
              },
            ],
          },
          {
            subject: "You had close contact with",
            type: "personal",
            choices: [
              {
                text: "People / person that have been diagnosed with COVID-19",
                value: {
                  name: "ใกล้ชิดสัมผัสผู้ติดเชื้อ",
                  value: riskLevel.veryHigh,
                },
              },
              {
                text:
                  "People / person that have high risk (infected person coughed or sneezed at / stay with infected person / talked to infected person within 1 meter for over 5 minutes / stay with infected person in a dense area within 1 meter for over 5 minutes)",
                value: {
                  name:
                    "ใกล้ชิดผู้สัมผัสเสี่ยงสูง(ถูกผู้ป่วยไอจามใส่/อยู่บ้านเดียวกับผู้ติดเชื้อ/พูดคุยกับผู้ป่วยในระยะ 1 เมตรเกิน 5นาที/อยู่ในสถานที่แออัดร่วมกับผู้ป่วยในระยะ 1 เมตรเกิน 15 นาที)",
                  value: 2,
                },
              },
              {
                text:
                  "People / person that have low risk or family members work with high-risk people",
                value: {
                  name:
                    "ใกล้ชิดผู้สัมผัสเสี่ยงต่ำ/คนในครอบครัวทำงานกับกลุ่มเสี่ยง",
                  value: 1,
                },
              },
              {
                text: "No contact with above groups",
                value: { name: "ไม่ได้สัมผัส", value: 0 },
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    renderDataByMode() {
      if (this.mode === "EN") {
        return this.dataRender.en;
      } else {
        return this.dataRender.th;
      }
    },
    showTitleForm() {
      if (this.mode === "EN") {
        return this.titleForm.en;
      } else {
        return this.titleForm.th;
      }
    },
    showFactory() {
      if (this.mode === "EN") {
        return this.factoryForm.en;
      } else {
        return this.factoryForm.th;
      }
    },
  },
  methods: {
    switchLang(data) {
      localStorage.setItem("lang", data);
      this.mode = data;
    },
    logout() {
      this.$store.dispatch("setUserInfo", null);
      location.replace("/");
    },
    calScore(data) {
      const q1 = data.Q1TravelDesc.value;
      const q2 = data.Q2TravelMethod.value;
      const q3 = data.Q3VisitedProvince.value;
      const q4 = data.Q4Activities.value;
      const q5 = data.Q5CloseContactWith.value;
      if (q4 === this.riskLevel.veryHigh || q5 === this.riskLevel.veryHigh) {
        data.RiskLevel = this.riskLevel.veryHigh;
        data.Recommendations = "ตรวจเชื้อและ Self Quarantine 14 วัน";
        data.TotalScore = 99;
      } else {
        data.TotalScore = q1 + q2 + q3 + q4 + q5;
        if (data.TotalScore === 0) {
          data.RiskLevel = this.riskLevel.zero;
          data.Recommendations = "เข้าทำงานตามปกติ";
        } else if (data.TotalScore > 0 && data.TotalScore < 7) {
          // 1-6
          data.RiskLevel = this.riskLevel.normal;
          data.Recommendations = "เข้าทำงานตามปกติแต่ต้อง Self Monitoring";
        } else {
          data.RiskLevel = this.riskLevel.high; // 7-10
          data.Recommendations = "Work from Home และ Self Monitoring 14 วัน";
        }
      }
    },
    async submitForm(e) {
      e.preventDefault();
      try {
        this.formData.Q1TravelDesc = this.answers[0];
        this.formData.Q2TravelMethod = this.answers[1];
        this.formData.Q3VisitedProvince = this.answers[2];
        this.formData.Q4Activities = this.answers[3];
        this.formData.Q5CloseContactWith = this.answers[4];
        this.calScore(this.formData);
        const userInfo = _.get(this.$store, "getters.getUserInfo");
        if (!this.formData.Plant) {
          SwAlert.warning("Please select plant", "Warnning");
        } else {
          if (userInfo) {
            /** TODO: API ยิง */
            this.isLoading = true;
            const data = {
              SubmissionDateTime: new Date(),
              TotalScore: this.formData.TotalScore,
              RiskLevel: this.formData.RiskLevel,
              Recommendations: this.formData.Recommendations,
              SubmitterEmail: userInfo.email,
              IDCard: userInfo.idCardCode,
              FullName: userInfo.firstName + " " + userInfo.lastName,
              TimelineStart: Moment(this.formData.dateFrom).format(
                "YYYY-MM-DD"
              ),
              TimelineEnd: Moment(this.formData.dateTo).format("YYYY-MM-DD"),
              IsAD: userInfo.isAdUser,
              CompanyNonAD: _.get(userInfo, "company", ""),
              TelNo: userInfo.phoneNumber,
              Plant: this.formData.Plant.name,
              Q1TravelDesc: this.formData.Q1TravelDesc.name,
              Q2TravelMethod: this.formData.Q2TravelMethod.name,
              Q3VisitedProvince: this.formData.Q3VisitedProvince.name,
              Q4Activities: this.formData.Q4Activities.name,
              Q5CloseContactWith: this.formData.Q5CloseContactWith.name,
              Version: "0.0.5",
            };
            await Axios.post(baseSubmitUrl, data);
            this.isLoading = false;
            this.showModal = true;
          }
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-survey {
  .timeline {
    display: flex;
    justify-content: left;
  }
  .label-timeline {
    color: #083c5d;
    font-weight: bold;
    font-size: 16px;
  }
  label.subject {
    font-weight: bold;
  }
  .operation {
    text-align: center;
  }
  .vue-select {
    width: 175px;
  }
  @media only screen and (max-width: 576px) {
    .plantselect {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
